import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Rightcontainer from "../Components/Rightcontainer";
import Header from "../Components/Header";
import "../css/terms.css";
import axios from "axios";
import { Interweave } from "interweave";
const Shipping = () => {
  const [data, setData] = useState();

  // BASE URL

  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }
  // +++++++++++++++++++++++++++++

  const getdata = () => {
    axios.get(baseUrl + `api/term/condition/Refund_Policy`).then((res) => {
      setData(res.data[0].Desc);
    });
  };
  useEffect(() => {
    getdata();
  }, []);

  return (
    <div>
      <div className="leftContainer mb_space">
        <div className="mt-5 py-4 px-3">
          <div className="m-3">
            <h1 className="profile_headings" style={{color:"black"}}>Refund Policy</h1>

            <>
              <p className="p2 small_para">
              Thank you for choosing Roxx Ludo (Roxxludo) for your furniture needs. We want to make sure your experience with us is as smooth and enjoyable as possible. Please review our shipping policy to understand how we handle the shipment of your orders.
              </p>
              <p className="other_page_small_headings">
              Processing Time:
              </p>
              <p className="p2 small_para">
              Orders are typically processed within 1-3 business days of receiving payment. Customized or made-to-order items may require additional processing time, and specific details will be communicated upon purchase.
              </p>
              <p className="other_page_small_headings">
              Delivery Timeline:
              </p>
              <p className="p2 small_para">
              Estimated delivery times will be provided during the checkout process. Please note that delivery times may vary based on your location, selected shipping method, and any unforeseen circumstances such as weather or carrier delays.
              </p>
             
              <p className="p2 small_para">For customers in India, we aim to deliver your products within 7-12 days of placing the order.</p>
              <p className="other_page_small_headings">
              Shipping Methods:
              </p>
              <p className="p2 small_para">
              We offer standard and expedited shipping options during the checkout process. Shipping costs are calculated based on the weight, size, and destination of the items in your order.
              </p>
              <p className="other_page_small_headings">
              Shipping Destinations:
              </p>
              <p className="p2 small_para">
              We currently ship to addresses within India. Please note that certain items may have restrictions on international shipping due to size and weight.
              </p>
              <p className="p2 small_para">
              If you have any questions or concerns about our shipping policy, feel free to contact our customer service team at jkb20009@gmail.com or call us at 8529691600.
              </p>
              <p className="p2 small_para">
              Thank you for choosing Roxxludo Ludo (Roxxludo)!
              </p>
            </>
          </div>
        </div>
      </div>
      <div className="rightContainer">
        <Rightcontainer />
      </div>
    </div>
  );
};
export default Shipping;
